.catering_section {
	margin-top: 4rem;
}
.catering_section_text {
	width: 100%;
}
.catering_section_title,
.catering_company_genre {
	width: 100%;
	font-size: 1.8rem;
	background: #77543b;
	color: #fff;
	line-height: 1;
	padding: 1.4rem 1rem;
	margin-bottom: 2rem;
}
.catering_company_genre {
	margin-bottom: 1rem;
}
.catering_section_text ul {
	padding-left: 1rem;
}
.catering_section_text li {
	font-size: 1.6rem;
	text-indent: -1.25em;
	padding-left: 1.25em;
}
.catering_section_notice {
	padding-left: 1rem;
	color: #ff0000;
	padding-top: .4rem;
}
.catering_section_image {
	margin-top: 2rem;
	figcaption {
		font-size: 1.2rem;
		color: #aaa;
		text-align: center;
		padding-top: .6rem;
	}
}
.catering_company {
	width: 100%;
	padding: 4rem 1.5rem 0;
}
.catering_company_table thead {
	display: none;
}
.catering_company_table .numbering {
	display: none;
}
.catering_company_table {
	font-size: 1.4rem;
}
.catering_company_table,
.catering_company_table tbody,
.catering_company_table tr {
	display: block;
}
.catering_company_table tr {
	border: 2px solid #77543b;
	& + tr {
		margin-top: 2rem;
	}
}
.catering_company_table tbody td {
	display: flex;
	border-bottom: 1px solid #c7beab;
	line-height: 1;
}
.catering_company_table tbody b,
.catering_company_table tbody span {
	display: block;
	padding: 1rem;
}
.catering_company_table tbody {
	--tbodyBwidth: 8.8rem;
}
.catering_company_table tbody b {
	width: var(--tbodyBwidth);
	background: #fdf0d4;
	color: #77543b;
	border-right: 1px solid #c7beab;
}
.catering_company_table tbody span {
	width: calc(100% - var(--tbodyBwidth));
	&:empty::before{
		content: "-";
	}
}
.catering_company_table tbody a[href *= "tel:"] {
	white-space: nowrap;
}

@media (min-width: 600px) {
	.catering_section {
		--cateringSectionImageWidth: 24rem;
	}
	.catering_section + .catering_section {
		margin-top: 6rem;
	}
	.catering_section_title {
		margin-bottom: 3rem;
	}
	.catering_section_text {
		width: calc(100% - var(--cateringSectionImageWidth));
	}
	.catering_section_image {
		width: var(--cateringSectionImageWidth);
		padding-left: 2rem;
		margin-top: 0;
	}
	.catering_section_text li {
		& + li {
			margin-top: .4rem;
		}
	}
	.catering_company_genre {
		margin-bottom: 2rem;
	}
	.catering_company {
		padding-top: 8rem;
	}
	.catering_company_table tr {
		& + tr {
			margin-top: 2.8rem;
		}
	}
	.catering_company_table tbody b,
	.catering_company_table tbody span {
		padding: 1.4rem;
	}
	.catering_company_table tbody b {
		--tbodyBwidth: 12rem;
	}
}
@media (min-width: 1000px) {
	.catering_section_title,
	.catering_company_genre {
		font-size: 2rem;
		padding: 1.2rem;
	}
	.catering {
		.catering_section {
			--cateringSectionImageWidth: 26rem;
		}
	}
	.catering_section_text li {
		font-size: 1.8rem;
		& + li {
			margin-top: 1rem;
		}
	}
	.catering_section_notice {
		padding-top: .8rem;
	}
	.catering_company_table {
		display: table;
		width: 100%;
		border: 2px solid #77543b;
	}
	.catering_company_table th,
	.catering_company_table td {
		padding: 0 .3rem;
	}
	.catering_company_table thead {
		display: table-header-group;
		background: #fdf0d4;
		white-space: nowrap;
		border-bottom: 2px solid #77543b;
		th {
			line-height: 1;
			padding-top: 1.4rem;
			padding-bottom: 1.4rem;
		}
	}
	.catering_company_table thead th:not(:last-of-type) {
		border-right: 1px solid #c7beab;
	}
	.catering_company_table tbody {
		display: table-row-group;
	}
	.catering_company_table tr {
		display: table-row;
		border: none;
	}
	.catering_company_table .numbering {
		display: table-cell;
		text-align: center;
	}
	.catering_company_table tbody .nowrap {
		white-space: nowrap;
	}
	.catering_company_table tbody td {
		display: table-cell;
		border-right: 1px solid #c7beab;
	}
	.catering_company_table tbody td.align-center {
		text-align: center;
	}
	.catering_company_table tbody td.align-right {
		text-align: right;
	}
	.catering_company_table tbody tr td:last-of-type {
		border-right: none;
	}
	.catering_company_table tbody b {
		display: none;
	}
	.catering_company_table tbody b,
	.catering_company_table tbody span {
		padding: 1rem;
	}
	.catering_company_table tbody span {
		width: 100%;
		line-height: 1.4;
	}
	.catering_company_table tbody a:not([href *= "tel"]) {
		word-break: break-all;
	}
}